function Description() {
  return (
    <>
      <div className="text-center">
        <h1 className="text-5xl font-bold">Generate Phone Numbers!</h1>
        <p className="py-6">
          Just put in your starting phone number and how many consecutive numbers you need. Then, we'll provide you with a downloadable CSV!
        </p>
      </div>
    </>
  );
}
export default Description;
