import { useState, useEffect } from "react";

function PhoneNumberForm() {
  const selectOptions = [
    "Customer Service",
    "Debt Collection",
    "Informational/Notification",
    "Charity",
    "Political",
    "Survey",
    "Telemarketing",
    "Multi-Use Line",
    "Prison*",
    "Do Not Originate",
  ];
  const [phoneNumber, setPhoneNumber] = useState("");
  const [purpose, setPurpose] = useState(selectOptions[0]);
  const [inc, setInc] = useState(1);
  const [prepend, setPrepend] = useState(false);
  const [results, setResults] = useState([]);

  useEffect(() => {
    getNumber();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNumber = async () => {
    let localNumber = await fetchNumberFromLocalStorage();

    if (!localNumber) {
      localNumber = await fetchNumberFromApi();
    }

    const newNumber = localNumber.replace(/\D+/g, "");

    setPhoneNumber(newNumber);
  };

  const fetchNumberFromLocalStorage = async () => {
    try {
      const localNumbers = await JSON.parse(
        localStorage.getItem("phoneNumbers")
      );

      if (localNumbers && localNumbers.length > 0) {
        const firstNumber = localNumbers.shift();
        localStorage.setItem("phoneNumbers", JSON.stringify(localNumbers));

        return firstNumber;
      }
    } catch (error) {
      console.log(error);
    }

    return null;
  };

  const fetchNumberFromApi = async () => {
    const key = process.env.REACT_APP_PHONE_NUMBER_KEY;

    const response = await fetch(
      `https://randommer.io/api/Phone/Generate?CountryCode=US&Quantity=101`,
      {
        method: "GET",
        headers: {
          "X-Api-Key": key,
        },
      }
    );

    const data = await response.json();

    const apiNumber = data.shift();

    localStorage.setItem("phoneNumbers", JSON.stringify(data));

    return apiNumber;
  };

  const handleSubmit = async () => {
    setResults([]);

    const obj = {
      phoneNumber: +phoneNumber,
      purpose,
      inc,
      prepend,
    };

    // Generate List
    generateNumbers(obj);

    // Reset Form
    setPurpose(selectOptions[0]);
    setInc(1);
    setPrepend(false);
    // await getNumber()
  };

  const generateNumbers = (obj) => {
    const { phoneNumber, purpose, inc, prepend } = obj;
    const resultArray = [];

    for (let i = 0; i < inc; i++) {
      const newNumber = `${prepend ? "+" : ""}${phoneNumber + i},${purpose}`;
      resultArray.push(newNumber);
    }

    setResults(resultArray);
  };

  const downloadNumbers = async () => {
    const csvContent = "data:text/csv;charset=utf-8," + results.join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    const id = Date.now();
    link.setAttribute("id", id);
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${results.length}-phone-numbers.csv`);
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the data file named "my_data.csv".
    document.body.removeChild(document.getElementById(id));
  };

  const formatCount = async (e) => {
    const regex = /^[0-9\b]+$/;
    const value = e.target.value;
    if (value === 0 || regex.test(value)) {
      setInc(value);
    }
  };

  const formatPhone = async (e) => {
    const regex = /^[0-9\b]+$/;
    const value = e.target.value;
    if (
      (value === 0 || regex.test(value)) &&
      value >= 1 &&
      value <= 99999999999
    ) {
      setPhoneNumber(value);
    }
  };

  return (
    <>
      <div className="card flex-shrink-0 w-full max-w-sm mx-auto shadow-2xl bg-base-100">
        <div className="card-body">
          <div className="form-control">
            <label className="label">
              <span className="label-text">Initial Phone Number</span>
            </label>
            <input
              min={1}
              max={99999999999}
              type="number"
              placeholder="Starting number?"
              id="phoneNumber"
              value={phoneNumber}
              onChange={formatPhone}
              className="input input-bordered"
            />
          </div>
          <div className="form-control w-full max-w-xs">
            <label className="label">
              <span className="label-text">Call Purpose</span>
            </label>
            <select
              className="select select-bordered"
              value={purpose}
              onChange={(e) => setPurpose(e.target.value)}
            >
              {selectOptions.map((option, index) => (
                <option key={index}>{option}</option>
              ))}
            </select>
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Count</span>
            </label>
            <input
              min={1}
              max={10_000}
              type="number"
              placeholder="How many?"
              id="inc"
              value={inc}
              onChange={formatCount}
              className="input input-bordered"
            />
          </div>
          <div className="form-control">
            <label className="label cursor-pointer">
              <span className="label-text">Prepend with (+)?</span>
              <input
                type="checkbox"
                className="checkbox checkbox-primary"
                checked={prepend}
                onChange={() => setPrepend(!prepend)}
              />
            </label>
          </div>
          <div className="form-control mt-6">
            <button className="btn btn-primary" onClick={() => handleSubmit()}>
              Generate
            </button>
          </div>
        </div>
      </div>
      {results.length > 0 && (
        <button className="btn btn-link my-7" onClick={downloadNumbers}>
          Download {results.length} number(s)
        </button>
      )}
    </>
  );
}
export default PhoneNumberForm;
