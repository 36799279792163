import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/Home';

function App() {
    return (
        <div className='md:container md:w-50 md:mx-auto bg-base-200'>
            <Router>
                <div className='flex flex-col justify-between min-h-screen h-full'>
                    <main className='container mx-auto px-3 pb-12'>
                        <Routes>
                            <Route path='/' element={<Home />} />
                        </Routes>
                    </main>
                </div>
            </Router>
        </div>
    );
}

export default App;
