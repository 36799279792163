import Description from "../components/Description";
import PhoneNumberForm from "../components/PhoneNumberForm";

function Home() {
    return (
        <>
            <div className="mt-7">
                <div className="text-center">
                    <div className="max-w-100">
                        <Description />
                        <div className="max-w-md mx-auto my-7">
                            <PhoneNumberForm />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Home;
